import { Grid } from "@mui/material";

const VisionAI = () => {

    return (
        
       <>
        <Grid marginTop={2}>
        <div>
            Apply Vision AI to Identify Objects to build NoDisturbance App

            <p> TBD </p>
        </div>



        </Grid>
     </>              
    )
}

export default VisionAI;


