import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import ProfileSite from "./profile-site";
import RasberryRobot from "./rasberry-robot";
import VisionAI from "./vision-ai";


const ExperimentResource = () => {

    let params = useParams();
    const expId = params['expId'];
    console.log("Param for experiements is : " + expId)
    return (
        
       <>
        
        <Grid padding={10}>
                <Grid  >
                     {/* <Item>xs=3</Item> */ }
                 </Grid>         
                 <Grid  >
            {       
                (() => {
                    if(expId === '10001') {
                            return (
                                <ProfileSite/>
                            )
                    } else if (expId === '10002') {
                            return (
                            <RasberryRobot/>
                            )
                    } else if (expId === '10003') {
                        return (
                        <VisionAI/>
                        )
                }
                    else {
                            return (
                                <p>Not found</p>
                            )
                        }
                })()  
           
            }  
                 </Grid>
                <Grid >
                                    { /* <Item>xs=3</Item> */}
                </Grid>
        </Grid>

     
     </>              
    )
}

export default ExperimentResource;
