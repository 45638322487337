import { Grid } from "@mui/material";

const RasberryRobot = () => {

    return (
        
       <>
        <Grid marginTop={2}>
            <div>
                Build intelligent Robot with Rasberry Pi and AI. 

                <p> TBD </p>

            </div>
        </Grid>
     </>              
    )
}

export default RasberryRobot;

