
import  React, {FormEvent,} from 'react'
import { Form, useActionData} from 'react-router-dom';

import {TextField, Button} from '@mui/material'
import { Link, redirect } from 'react-router-dom';
import { ActionFunction, ActionFunctionArgs } from 'react-router';



const ContactMeForm = () => {

   
        const data: any = useActionData()


    return (
        <>
            <Form method='POST' action='/contactme' >
            <div style={{ margin: "1em", textAlign: "center" }}>
                    <h2>Contact Me Form</h2>
            </div>
            <h4> Please submit the form, and I will get back to you via email. Thank you..! </h4>
            <TextField 
                label="Your Email"
                name="email"
           //     onChange={e => contactState.email = e.target.value}
                required
                variant="outlined"
                color="secondary"
                type="email"
                sx={{mb: 3}}
                fullWidth={true}
               // value={email}
               // error={emailError}
 
                 />
            <TextField 
                label="Your Name"
                name="name"
             
              //  onChange={e => contactState.name = e.target.value}
                required
                variant="outlined"
                color="secondary"
                type="text"
                sx={{mb: 3}}
                fullWidth
               // value={email}
               // error={emailError}
                 />
            <TextField 
                label="Your Question or Inquiry"
                name="question"
       
           //     onChange={e => contactState.question = e.target.value}
                required
                variant="outlined"
                color="secondary"
                type="text"
                sx={{mb: 3}}
                fullWidth
               // value={email}
               // error={emailError}
                 />
               
            <Button variant="outlined" color="secondary" type="submit">Submit</Button>

            {data && data.error && <p style={{color: 'red'}}>{data.error}</p>}
            </Form>
        </>
    )
}

export default ContactMeForm;

export const contactMeAction: ActionFunction = async ({ request, params }) => {

    const data = await request.formData()

    const formInfo = {
        email: data.get('email'),
        name: data.get('name'),
        question: data.get('question'),
        date: new Date().toDateString()
    }

    console.log(formInfo)
    fetch('/api/contactme', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formInfo)
    }).then(response => {
        console.log(response)
        return {success: response};
    }).catch(err => {
        console.log(err) 
        return {error: 'Error while submiting contactme form - ' + err}
    })

    return redirect('/contactme/thankyou')
}   


