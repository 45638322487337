import React, {FormEvent, useReducer, useState} from 'react'
import { ActionFunction, Form, redirect, useActionData } from 'react-router-dom'

import {TextField, FormControl, Button} from '@mui/material'

const FeedbackForm = () => {

    const data: any = useActionData()
    return (
        <>
            <Form method='POST' action="/feedback">
            <div style={{ margin: "1em", textAlign: "center" }}>
                    <h2>Feedback Form</h2>
            </div>
            <h4> Please submit feedback form for me. Thank you..! </h4>
            <TextField 
                label="Your Email"
                name="email"
               // onChange={e => feedbackState.email = e.target.value}
                required
                variant="outlined"
                color="secondary"
                type="email"
                sx={{mb: 3}}
                fullWidth
                //value={feedbackState.email}
                // error={emailError}
                 />
            <TextField 
                label="Your Name"
                name="name"
                // onChange={e => feedbackState.name = e.target.value}
                required
                variant="outlined"
                color="secondary"
                type="text"
                sx={{mb: 3}}
                fullWidth
               // value={feedbackState.name}
               // error={emailError}
                 />
            <TextField 
                label="Your Feedback"
                name="feedback"
               // onChange={e => feedbackState.feedback = e.target.value}
                required
                variant="outlined"
                color="secondary"
                type="text"
                sx={{mb: 3}}
                fullWidth
               // value={feedbackState.feedback}
               // error={emailError}
                 />
            <Button variant="outlined" color="secondary" type="submit">Submit</Button>
            {data && data.error && <p style={{color: 'red'}}>{data.error}</p>}
            </Form>
        </>
    )
}

export default FeedbackForm;

export const  handleFeedbackAction: ActionFunction = async ({request, params}) => {
   
    const data: any = await request.formData()

    const formInfo = {
        email: data.get('email'),
        name: data.get('name'),
        feedback: data.get('feedback'),
        date : new Date().toDateString()
    }

    fetch('/api/feedbacks', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formInfo)
    }).then(response => {
        console.log(response)
        return {success : response};
    }).catch(err => {
        console.log(err)
        return {error: 'Error while submitting feedback form : ' + err}
    })

    return redirect('/feedback/thankyou')
}