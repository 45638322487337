import { Box, Grid, Paper, Typography } from "@mui/material";

const ProfileSite = () => {

    return (
        
       <>

            <Grid item >
                <Box >
                    <Typography variant="h6" component='h6' > Modern Personal Profile WebApp using SPA and APIs </Typography>
                    <Typography variant="subtitle1"  sx={{fontSize:12, fontStyle:'italic'}} > Date : June/July 2024 </Typography>
                </Box>
            </Grid>
            <Grid item>
                <Typography variant="subtitle1" paragraph> 
                <br/>
                    I had computer science as subject in my school course work. It included Html, CSS, Javascript and static pages to build a small website and host it locally. 
                    I was curious to understand how big internet companies build and maintain such complex and dynamic apps? What technologies they use? What process goes there? How do they deploy and change it regularly.
                    I did research on internet and found out there is more to it than that shows on the site. 

                    <p>
                    That triggered me to explore, learn and apply how to build modern web app for my personal profile and host it on the internet. I began to think what to include as features for my personal site? I quickly formed a basic rough
                    draft on paper how the front page can look. I wanted it to introduce myself and make it feel like personal connection with the reader. I also learnt about broader process that internet companies follow in building and maintaning web apps.
                    It involves business usecase, identify need to fulfill for customer/personas, build/review  UX/UI design with user flows, 
                    building prototype, validations and then building out full web app using a technology stack and iterative development.
                    </p>
                    <Typography variant="h6" paragraph> React JS - Component library</Typography>
                    <p>
                    I started by learning types of web app, concept of server rendering vs client side rendering. The concept of Single Page Application (SPA) is interesting and many sites are built on this concept. 
                    I began understanding SPAs and their dependency on APIs for data. I started small with building a simple frontend template app with ReactJS library, a popular SPA library opensourced by Meta. 
                    React is a layer above Javascript that allows to mix Html, CSS and JS, to build React reusable component model that can be reused, customized and composed. 
                    I spent good time to learn how to use React library, build & use components, and compose pages using components. Open source has so much to offer here. There are plenty of internet content to learn on this topic. I went in deep 
                    and started building small nuggets, incrementally adding new features.
                    </p>

                    <p>
                    I started with main page layout. Using Material UI open source component library makes it simpler to compose ready components like Grid, Appbar, Avatar, Stack, etc, which are building blocks to create new Header component. 
                        <br></br>
                        <Box component='img'
                            src="/images/details/header-img.jpeg"
                            sx={{
                                height: 80,
                                width: 1200,
                                maxHeight: { xs: 50, md: 80 },
                                maxWidth: { xs: 400, md: 800 },
                                padding: 1
                            }}>
                            
                        </Box>
                        <br></br>
                        <Typography variant="h6" paragraph> Material UI - Open source component library</Typography>       
                         Slowly, I learnt how to use Material UI components and build composed applications. It took time, patience  and perseverance to learn, but it was exciting. 
                         On the other side, UI components need to work with server APIs to fetch relevant data items. Next, I built a first fetch API to get all experiments metadata 
                         from API. This API will be consumed by experiments component on UI which will render information in a dynamic cards for better view. 

                         <br></br>     
                         <Box component='img'
                            src="/images/details/experiments-img.jpeg"
                            sx={{
                                height: 300,
                                width: 800,
                                maxHeight: { xs: 100, md: 200 },
                                maxWidth: { xs: 250, md: 400 },
                                border:1,
                                padding: 2
                            }}>
                            
                        </Box>
                        <br></br>
                        
                        Another usecase I added is 'ContactMe' and 'Feedback' links and forms. The form will be posted to a POST API which will extract data information
                        and save it to a file for now. It could be a database as next step. 
                        
                        <br></br>
                        <Box component='img'
                            src="/images/details/contactme-img.jpeg"
                            sx={{
                                height: 300,
                                width: 800,
                                maxHeight: { xs: 100, md: 200 },
                                maxWidth: { xs: 250, md: 400 },
                                border:1,
                                padding: 2
                            }}>
                            
                        </Box>
                        <br></br>
                        <Typography variant="h6" paragraph> APIs with Nodejs </Typography>
                        Lastly you provide APIs from NodeJs app, with different endpoints supporting UI data fetch. using Nodejs express api framework, 
                        I wrote simple APIs. 
                        <Box component='img'
                            src="/images/details/api-server-img.jpeg"
                            sx={{
                                height: 300,
                                width: 800,
                                maxHeight: { xs: 100, md: 200 },
                                maxWidth: { xs: 250, md: 400 },
                                border:1,
                                padding: 2
                            }}>
                            
                        </Box>
                        <br></br>
                        <Typography variant="h6" paragraph> Deploy to Google Cloud</Typography>
                        Next I came to the question of how to deploy it to internet? After consulting, I came to understand that there are many options to deploy.
                        One of them is to deploy to Google cloud App Engine. Google cloud offers credits to use its services. I opened an account and learnt basics how to use 
                        App Engine. Its quite simple to use. Install gcloud sdk on your laptop, and using terminal issue commands to deploy the app. It need deployment descriptor configuration
                        for deployment. I also needed storage bucket to store app engine static assets. From laptop runing deploy command runs 

                        <br></br>
                        
                        It was great learning experience. I feel motivated to continue learning further. There are many things to learn further. 
                        I hope to add features and continue adding new projects I take on from time to time. 

                        <br></br>
                        The project is hosted on google cloud. And source code can be found on my Github link here: 

                        Thank you for reading..! 
                        
                        Please leave feedbck comments.
                      
                    </p>


                </Typography>
            </Grid>
      
     </>              
    )
}

export default ProfileSite;


