import React from 'react';
import {
 createBrowserRouter, createRoutesFromElements, RouterProvider, Route, 
} from "react-router-dom";
import {lazy, Suspense} from "react";
import { contactMeAction } from './contact-me-form';
import { handleFeedbackAction } from './feedback-form';
import ExperimentResource from './experiments/experiments-resource';

const Home = lazy(() => import("./main"));
const Root = lazy(() => import ( "./root"));
const Profile = lazy(() => import( "./profile"));
const Experiment = lazy(() =>  import ( "./experiments"));
const PageNotFound = lazy(() => import("./page-not-found"));
const Feedback = lazy(() => import("./feedback"));
const ContactMe = lazy(() => import("./contactme"));
const AdminRoot = lazy(() => import("./admins/admin-root"));
const AdminHome = lazy(() => import("./admins/admin-home"));
const Thankyou = lazy(() => import("./thankyou"));

const MyBrowserRouter = createBrowserRouter(
  
  createRoutesFromElements(

    <Route path="/" element={<Root/>} errorElement={<PageNotFound/>} >

      <Route  index={true} element={<Home/>}/>
      <Route path='/experiments'  errorElement={<PageNotFound/>}>
      
        <Route index={true} element={<Experiment/>}/>
        <Route path=':expId' element={<ExperimentResource/>}/>
        <Route path='spa-website' element={<h2>Website with React and APIs</h2>} />
        <Route path='rasberry-pi' element={<h2>Rasberry Pi Robot</h2>} />
        <Route path='vision-ai' element={<h2>Vistion AI for NoDisturbance App</h2>} />
      </Route>
      <Route path='/profile'  element={<Profile/>}/>
      <Route path='/contactme' element={<ContactMe/> } action={contactMeAction}>
        
      </Route>
      <Route path='/contactme/thankyou' element={<Thankyou messagePart="Contacting me"/>}/>
      <Route path='/feedback' element={<Feedback/>} action={handleFeedbackAction}/>
      <Route path='/feedback/thankyou' element={<Thankyou messagePart="feedback"/>}/>
      <Route path='/*' element={<Home/>}/>
      <Route path="/admins" element={<AdminRoot/>} errorElement={<PageNotFound/>}>

        <Route index={true} element={<AdminHome/>}/>

      </Route>
     
    </Route>
  )
);

export default MyBrowserRouter;
